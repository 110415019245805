import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { LabelMudah, NavigationTabV2 } from 'components/molecules'
import styles from 'styles/components/organisms/carRecomendations.module.scss'
import stylec from 'styles/components/organisms/cardetailcard.module.scss'
import stylex from 'styles/components/organisms/testimonyWidget.module.scss'
import stylep from 'styles/components/organisms/lpCarRecommendations.module.scss'
import EmptyCarImage from '/public/revamp/illustration/empty-car.webp'
import { Swiper as SwiperType } from 'swiper'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import urls from 'utils/helpers/url'
import { Button } from 'components/atoms'
import {
  IconChevronLeft,
  IconChevronRight,
  IconThumbsUp,
} from 'components/atoms/icon'
import { colors } from 'utils/helpers/style/colors'
import { sendAmplitudeData } from 'services/amplitude'
import { AmplitudeEventName } from 'services/amplitude/types'
import elementId from 'utils/helpers/trackerId'
import LPCRSkeleton from '../lpSkeleton/carRecommendation'
import { LabelPromo } from 'components/molecules'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { AlternativeCarCard } from '../alternativeCarCard'
import { PopupPromo } from '../popupPromo'
import { CookieKey, LocalStorageKey } from 'utils/enum'
import { ButtonSize, ButtonVersion } from 'components/atoms/button'
import {
  PreviousButton,
  RouteName,
  navigateToPLP,
  saveDataForCountlyTrackerPageViewLC,
  saveDataForCountlyTrackerPageViewPDP,
} from 'utils/navigate'
import { AdaOTOdiSEVALeadsForm } from '../leadsForm/adaOTOdiSEVA/popUp'
import {
  trackEventCountly,
  valueForUserTypeProperty,
} from 'services/countly/countly'
import { CountlyEventNames } from 'services/countly/eventNames'
import { useCar } from 'services/context/carContext'
import { useUtils } from 'services/context/utilsContext'
import { capitalizeFirstLetter, capitalizeWords } from 'utils/handler/string'
import { useOnceClick } from 'utils/hooks/useOnceClick'
import { CarRecommendation } from 'utils/types'
import dynamic from 'next/dynamic'
import { LeadsActionParam, PageOriginationName } from 'utils/types/tracker'
import { isOtrStatusVariantLive } from 'utils/handler/otrStatus'
import { getNewFunnelRecommendations } from 'utils/handler/funnel'
import { PopupPromoDataItemType } from 'utils/types/utils'
import { generateAllPromosForBadge } from 'utils/carModelUtils/carModelUtils'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'components/atoms/swiper'
import { NavigationTabV4 } from 'components/molecules/navigationTabV4'
import { getCity } from 'utils/hooks/useGetCity'
import { getCookies } from 'utils/handler/cookies'
import { HomePageDataLocalContext } from 'pages'
import { brandIconList, sizeBrandIcon } from 'utils/config'
import { WithDefaultImage } from 'components/atoms/withDefaultImage'
import { CarBrandItemResponse } from 'utils/types/models'

const LeadsFormPrimary = dynamic(
  () => import('components/organisms').then((mod) => mod.LeadsFormPrimary),
  { ssr: false },
)

type LPCarRecommendationsProps = {
  dataReccomendation?: any
  onClickOpenCityModal: () => void
  page?: string
  isOTO?: boolean
  isFilter?: boolean
  pageOrigination?: string
}

const LpCarRecommendations = ({
  dataReccomendation,
  onClickOpenCityModal,
  page = 'main',
  isOTO = false,
  isFilter = false,
  pageOrigination,
}: LPCarRecommendationsProps) => {
  const router = useRouter()
  const { dataCarBrand } = useContext(HomePageDataLocalContext)
  const isDealer = router.asPath.includes('dealer')
  const getUrlBrand = router.query.brand?.toString() ?? ''
  const getUrlLocation =
    router.query.location?.toString().replaceAll('-', ' ') ?? 'Indonesia'
  const { saveDataLeads } = useUtils()
  const { recommendation: recommendationFromContext } = useCar()
  const swiperRef = useRef<SwiperType>()
  const [recommendationList, setRecommendationList] = useState<
    CarRecommendation[]
  >(!!dataReccomendation ? dataReccomendation : recommendationFromContext)
  const [city] = useLocalStorage(LocalStorageKey.CityOtr, null)
  const [openPromo, setOpenPromo] = useState(false)
  const [selectedBrand, setSelectedBrand] = useState(
    page !== 'main'
      ? getUrlBrand !== 'bmw'
        ? capitalizeFirstLetter(getUrlBrand)
        : getUrlBrand.toUpperCase()
      : '',
  )
  const [load, setLoad] = useState(false)
  const [isModalOpenend, setIsModalOpened] = useState<boolean>(false)
  const [openLeadsModal, setOpenLeadsModal] = useState(false)
  const [selectedCar, setSelectedCar] = useState({
    Car_Brand: '',
    Car_Model: '',
  })
  const cityOtr = getCookies(CookieKey.CityOtr) || getCity()
  const [promoModel, setPromoModel] = useState<PopupPromoDataItemType[]>()
  const { onClickOnce } = useOnceClick()
  const getCityUrl = () => {
    if (cityOtr) return `${cityOtr.cityName.toLowerCase().replace(' ', '-')}`
  }
  const [selectedCarItemOnPromoBadge, setSelectedCarItemOnPromoBadge] =
    useState<CarRecommendation>()
  const [
    isLoadingNavigteToLoanCalculator,
    setIsLoadingNavigateToLoanCalculator,
  ] = useState(false)

  const parseHeightWidth = (item: CarBrandItemResponse, type: string) =>
    parseInt(
      sizeBrandIcon[item.makeName as keyof typeof sizeBrandIcon]?.split(',')[
        type === 'width' ? 0 : 1
      ],
    ) || 24

  const brandListAstra = useMemo(() => {
    const adddedBrand = dataCarBrand.map((item) => ({
      label: (
        <div className={styles.tabBrand}>
          <div className={styles.iconWrapper}>
            <WithDefaultImage
              alt={`${item.makeName} Car Recommendation`}
              defaultSrc={
                brandIconList[item.makeName as keyof typeof brandIconList]
              }
              src={item.logoUrl}
              width={parseHeightWidth(item, 'width')}
              height={parseHeightWidth(item, 'height')}
              priority
              style={{ width: 'auto', height: 'auto' }}
            />
          </div>
          {item.makeName}
        </div>
      ),
      value: item.makeName,
    }))

    return [
      {
        label: 'Semua',
        value: '',
      },
      ...adddedBrand,
    ]
  }, [dataCarBrand])

  const scrollToLeadsForm = (
    e: React.MouseEvent<HTMLAnchorElement | MouseEvent>,
    brand: string,
    model: string,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedCar({ Car_Brand: brand, Car_Model: model })
    setOpenLeadsModal(true)
  }

  const calculateHref = (item: CarRecommendation) => {
    const selectedCity = city ? city.cityName : 'Jakarta Pusat'
    const path = urls.internalUrls.loanCalculatorWithCityBrandModelUrl
      .replace(
        ':cityName/:brand/:model',
        selectedCity.replace(/ +/g, '-') +
          '/' +
          item.brand.replace(/ +/g, '-') +
          '/' +
          item.model.replace(/ +/g, '-') +
          '/',
      )
      .toLocaleLowerCase()
    return path
  }

  const trackCountlyCtaClick = (
    carBrand: string,
    carModel: string,
    pageDirectionUrl: string,
  ) => {
    trackEventCountly(CountlyEventNames.WEB_CAR_RECOMMENDATION_CTA_CLICK, {
      PAGE_ORIGINATION: pageOrigination,
      CAR_BRAND: 'Null',
      CAR_MODEL: 'Null',
      CAR_BRAND_RECOMMENDATION: carBrand,
      CAR_MODEL_RECOMMENDATION: carModel,
      CTA_BUTTON: 'Hitung Kemampuan',
      PAGE_DIRECTION_URL: pageDirectionUrl,
      TENOR_OPTION: 'Null',
      TENOR_RESULT: 'Null',
    })
  }

  const handleCalculateAbility = (
    e: React.MouseEvent<HTMLAnchorElement | MouseEvent>,
    item: CarRecommendation,
    index: number,
  ) => {
    e.preventDefault()
    e.stopPropagation()

    trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_LOAN_CALCULATOR_CLICK, {
      SOURCE_SECTION: 'Car Recommendation',
      CAR_BRAND: item.brand,
      CAR_MODEL: item.model,
      CAR_ORDER: index + 1,
      PAGE_ORIGINATION: pageOrigination,
    })
    const valueForLcPageViewTracker =
      pageOrigination === 'Homepage'
        ? PreviousButton.HomepageCarRecommendation
        : PreviousButton.CarRecommendationCta
    saveDataForCountlyTrackerPageViewLC(valueForLcPageViewTracker)
    const path = calculateHref(item)
    trackCountlyCtaClick(
      item.brand,
      item.model,
      'https://' + window.location.hostname + path,
    )
    setTimeout(() => {
      if (e.button === 0 && e.ctrlKey) {
        window.open(path, '_blank')
      } else {
        window.location.href = path
      }
    }, 1000)
  }
  const handleCalculateAbilityMiddleRight = (
    e: React.MouseEvent<HTMLAnchorElement | MouseEvent>,
    item: CarRecommendation,
    index: number,
  ) => {
    if (e.button === 1 || e.button === 2) {
      trackEventCountly(CountlyEventNames.WEB_HOMEPAGE_LOAN_CALCULATOR_CLICK, {
        SOURCE_SECTION: 'Car Recommendation',
        CAR_BRAND: item.brand,
        CAR_MODEL: item.model,
        CAR_ORDER: index + 1,
        PAGE_ORIGINATION: pageOrigination,
      })
      const valueForLcPageViewTracker =
        pageOrigination === 'Homepage'
          ? PreviousButton.HomepageCarRecommendation
          : PreviousButton.CarRecommendationCta
      saveDataForCountlyTrackerPageViewLC(valueForLcPageViewTracker)
      const path = calculateHref(item)
      trackCountlyCtaClick(
        item.brand,
        item.model,
        'https://' + window.location.hostname + path,
      )
    }
  }

  const detailCarHref = (item: CarRecommendation) => {
    const path = urls.internalUrls.variantListUrl
      .replace(
        ':brand/:model/:tab?',
        item.brand.replace(/ +/g, '-') +
          '/' +
          item.model.replace(/ +/g, '-') +
          '/' +
          getCityUrl(),
      )
      .toLocaleLowerCase()
    return path
  }
  const handleClickDetailCar = (
    e: React.MouseEvent<HTMLAnchorElement | MouseEvent | HTMLButtonElement>,
    item: CarRecommendation,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    saveDataForCountlyTrackerPageViewPDP(PreviousButton.CarRecommendation)

    const path = urls.internalUrls.variantListUrl
      .replace(
        ':brand/:model/:tab?',
        item.brand.replace(/ +/g, '-') +
          '/' +
          item.model.replace(/ +/g, '-') +
          '/' +
          getCityUrl(),
      )
      .toLocaleLowerCase()

    trackEventCountly(CountlyEventNames.WEB_CAR_RECOMMENDATION_CLICK, {
      PAGE_ORIGINATION: pageOrigination,
      PELUANG_KREDIT_BADGE: 'Null',
      CAR_BRAND: 'Null',
      CAR_MODEL: 'Null',
      CAR_BRAND_RECOMMENDATION: item.brand,
      CAR_MODEL_RECOMMENDATION: item.model,
      PAGE_DIRECTION_URL: window.location.hostname + path,
      TENOR_OPTION: 'Null',
      TENOR_RESULT: 'Null',
    })

    const newPath = window.location.pathname + path

    if (e.button === 0 && e.ctrlKey) {
      window.open(isOTO ? newPath : path, '_blank')
    } else {
      window.location.href = isOTO ? newPath : path
    }
  }
  const handleClickDetailCarMiddleRight = (
    e: React.MouseEvent<HTMLAnchorElement | MouseEvent>,
    item: CarRecommendation,
  ) => {
    if (e.button === 1 || e.button === 2) {
      saveDataForCountlyTrackerPageViewPDP(PreviousButton.CarRecommendation)

      const path = urls.internalUrls.variantListUrl
        .replace(
          ':brand/:model/:tab?',
          item.brand.replace(/ +/g, '-') +
            '/' +
            item.model.replace(/ +/g, '-') +
            '/' +
            getCityUrl(),
        )
        .toLocaleLowerCase()

      trackEventCountly(CountlyEventNames.WEB_CAR_RECOMMENDATION_CLICK, {
        PAGE_ORIGINATION: pageOrigination,
        PELUANG_KREDIT_BADGE: 'Null',
        CAR_BRAND: 'Null',
        CAR_MODEL: 'Null',
        CAR_BRAND_RECOMMENDATION: item.brand,
        CAR_MODEL_RECOMMENDATION: item.model,
        PAGE_DIRECTION_URL: window.location.hostname + path,
        TENOR_OPTION: 'Null',
        TENOR_RESULT: 'Null',
      })
    }
  }

  const handleClickLabel = (carItem: CarRecommendation) => {
    trackCountlyClickPromoBadge(carItem)
    setSelectedCarItemOnPromoBadge(carItem)

    const popupPromoData = generateAllPromosForBadge(
      carItem.variants?.[0].calculator,
    )
    setPromoModel(popupPromoData)
    trackCountlyViewPromoPopup(popupPromoData)

    setOpenPromo(true)
  }

  const handleShowRecommendation = (selectedBrandValue: string) => {
    setLoad(true)
    getNewFunnelRecommendations(
      !!selectedBrandValue
        ? { brand: [selectedBrandValue], sortBy: 'lowToHigh' }
        : { sortBy: 'lowToHigh' },
      undefined,
      undefined,
      undefined,
      5,
      1,
    )
      .then((response) => {
        setRecommendationList(response.carRecommendations)
        if (
          Array.isArray(response.carRecommendations) &&
          response.carRecommendations.length > 0
        ) {
          setTimeout(() => {
            swiperRef?.current?.slideTo(0)
          }, 500)
        }
      })
      .catch(() => {
        setRecommendationList([])
      })
      .finally(() => {
        setLoad(false)
      })
  }

  const closeLeadsForm = () => {
    setIsModalOpened(false)
  }

  const lihatSemuaMobil = () => {
    sendAmplitudeData(
      AmplitudeEventName.WEB_LP_BRANDRECOMMENDATION_CAR_SEE_ALL_CLICK,
      { Car_Brand: selectedBrand || 'Semua' },
    )
    trackEventCountly(CountlyEventNames.WEB_CAR_RECOMMENDATION_ALL_CLICK, {
      CAR_BRAND: selectedBrand || 'Semua',
      PAGE_ORIGINATION: pageOrigination,
    })

    if (!selectedBrand) {
      return isOTO
        ? (window.location.href = urls.internalUrls.duplicatedCarResultsUrl)
        : navigateToPLP(
            PreviousButton.undefined,
            {},
            true,
            false,
            undefined,
            true,
          )
    }

    const path = router.asPath.split('/')[1]
    if (path === 'adaSEVAdiOTO') {
      return router.push(`/adaSEVAdiOTO/mobil-baru/${selectedBrand}`)
    }

    navigateToPLP(PreviousButton.undefined, {
      search: new URLSearchParams({ brand: selectedBrand }).toString(),
    })
  }

  const trackCountlyClickTab = (brand: string) => {
    trackEventCountly(CountlyEventNames.WEB_CAR_RECOMMENDATION_TAB_CLICK, {
      CAR_BRAND_TAB: brand.length !== 0 ? brand : 'Semua',
      PAGE_ORIGINATION: pageOrigination,
    })
  }

  const trackLeads = (): LeadsActionParam => {
    return {
      Page_Origination: PageOriginationName.COTMLeadsForm,
      ...(cityOtr && { City: cityOtr.cityName }),
      Car_Brand: selectedCar?.Car_Brand,
      Car_Model: selectedCar?.Car_Model,
    }
  }

  const onClickInterested = (
    e: React.MouseEvent<HTMLAnchorElement | MouseEvent>,
    item: CarRecommendation,
  ) => {
    e.preventDefault()
    e.stopPropagation()
    saveDataLeads(item)
    setIsModalOpened(true)
  }

  const title = () => {
    switch (page) {
      case 'main':
        return 'Rekomendasi'
      case 'brand':
        return 'Rekomendasi Mobil Populer'
      case 'location':
        return `Rekomendasi Mobil ${
          getUrlBrand !== 'bmw'
            ? capitalizeFirstLetter(getUrlBrand)
            : getUrlBrand.toUpperCase()
        } OTR ${capitalizeWords(getUrlLocation)}`
      case 'succesPage':
        return 'Lihat Rekomendasi Mobil Baru di Seva.id'

      default:
        return 'Rekomendasi'
    }
  }

  const IconThumbs = () => (
    <div style={{ marginTop: '3.5px' }}>
      <IconThumbsUp width={14} height={14} />
    </div>
  )

  useEffect(() => {
    if (!dataReccomendation) {
      setRecommendationList(recommendationFromContext)
    }
  }, [recommendationFromContext])

  const onClickCtaPromoBadgePopup = () => {
    if (!!selectedCarItemOnPromoBadge) {
      const destinationUrl = calculateHref(selectedCarItemOnPromoBadge)
      trackCountlyClickPromoPopupCta(destinationUrl)
      const valueForLcPageViewTracker =
        PreviousButton.HomepageCarRecommendationPromoView
      saveDataForCountlyTrackerPageViewLC(valueForLcPageViewTracker)

      setIsLoadingNavigateToLoanCalculator(true)
      router.push(destinationUrl).finally(() => {
        setIsLoadingNavigateToLoanCalculator(false)
      })
    }
  }

  const trackCountlyClickPromoBadge = (carItem: CarRecommendation) => {
    const dataTracker = {
      PAGE_ORIGINATION: pageOrigination ?? RouteName.Others,
      CAR_BRAND: carItem.brand,
      CAR_MODEL: carItem.model,
      USER_TYPE: valueForUserTypeProperty(),
    }

    trackEventCountly(
      CountlyEventNames.WEB_CAR_RECOMMENDATION_PROMO_BADGE_CLICK,
      dataTracker,
    )
  }

  const trackCountlyViewPromoPopup = (
    popupPromoData: PopupPromoDataItemType[],
  ) => {
    const allPromoTitle = popupPromoData?.map((item) => item.title) ?? []
    const dataTracker = {
      PROMO_AMOUNT: popupPromoData?.length ?? 0,
      PROMO_TITLE: allPromoTitle.join(', ') ?? '',
      USER_TYPE: valueForUserTypeProperty(),
    }

    trackEventCountly(
      CountlyEventNames.WEB_CAR_RECOMMENDATION_PROMO_POPUP_VIEW,
      dataTracker,
    )
  }

  const trackCountlyClickPromoPopupCta = (destinationUrl: string) => {
    const dataTracker = {
      PAGE_ORIGINATION: pageOrigination ?? RouteName.Others,
      CAR_BRAND: selectedCarItemOnPromoBadge?.brand,
      CAR_MODEL: selectedCarItemOnPromoBadge?.model,
      PAGE_DIRECTION_URL: window.location.origin + destinationUrl,
      USER_TYPE: valueForUserTypeProperty(),
    }

    trackEventCountly(
      CountlyEventNames.WEB_CAR_RECOMMENDATION_USE_PROMO_CTA_CLICK,
      dataTracker,
    )
  }

  return (
    <>
      <div
        className={`${
          page === 'main' || page === 'succesPage'
            ? stylex.container
            : stylex.containerDealer
        }`}
      >
        <h2 className={stylep.title} style={{ marginBottom: 16 }}>
          {title()}
        </h2>
        <div style={{ padding: '0 16px' }}>
          {(page === 'main' || page === 'succesPage') && (
            <NavigationTabV4
              id="car-recom-nav-tab"
              additionalClassContainer={styles.tabMenuContainer}
              itemList={brandListAstra}
              onSelectTab={(value: any) => {
                setSelectedBrand(value)
                trackCountlyClickTab(value)
                handleShowRecommendation(value)
              }}
              selectedTab={selectedBrand}
            />
          )}
        </div>
        <div>
          {load ? (
            <LPCRSkeleton isRenderTitle={false} isRenderTabs={false} />
          ) : recommendationList.length === 0 ? (
            <div
              className={styles.alternativeCarWrapper}
              style={{ marginTop: 24, padding: '0 16px 32px' }}
            >
              <EmptyCarRecommendation
                onClickChangeCity={onClickOpenCityModal}
              />
            </div>
          ) : (
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={16}
              className={styles.alternativeCarWrapper}
              style={{ marginTop: 24, padding: '0 16px 32px' }}
              onBeforeInit={(swiper: SwiperType) =>
                (swiperRef.current = swiper)
              }
              chevronTop={'45%'}
              showArrow={recommendationList.length >= 3}
            >
              {recommendationList.slice(0, 5).map((item, index) => {
                const live = item.variants.some((x) =>
                  isOtrStatusVariantLive(x.otrInfo?.otrStatus),
                )

                return (
                  <SwiperSlide key={item.id} style={{ width: 212 }}>
                    <AlternativeCarCard
                      recommendation={item}
                      onClickLabel={() => {
                        handleClickLabel(item)
                      }}
                      isOTO={isOTO}
                      isDealer={page !== 'main'}
                      label={
                        isFilter && isDealer ? (
                          <LabelMudah
                            additionalClassname={stylec.labelCard}
                            onClick={() => {}}
                            prefixComponent={IconThumbs}
                          />
                        ) : item.isPassengerCar &&
                          generateAllPromosForBadge(
                            item.variants?.[0].calculator,
                          ).length > 0 ? (
                          <LabelPromo
                            className={stylec.labelCard}
                            onClick={() => {
                              handleClickLabel(item)
                            }}
                            boldText={`${
                              generateAllPromosForBadge(
                                item.variants?.[0].calculator,
                              ).length
                            } Promo`}
                          />
                        ) : (
                          <></>
                        )
                      }
                      pageOrigination={pageOrigination}
                      recommendationList={recommendationList.slice(0, 5)}
                    >
                      {(page === 'main' || page === 'succesPage') && (
                        <Link
                          href={detailCarHref(item)}
                          onClick={(e) => {
                            handleClickDetailCar(e, item)
                          }}
                          onMouseDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            onClickOnce(() =>
                              handleCalculateAbilityMiddleRight(e, item, index),
                            )
                          }}
                          style={{ width: '100%' }}
                        >
                          <button
                            className={styles.alternativeCarLink}
                            data-testid={elementId.PLP.Button.LihatSNK}
                          >
                            Lihat Detail
                          </button>
                        </Link>
                      )}
                      <Link
                        style={{ width: '100%' }}
                        href={
                          page === 'main' || page === 'succesPage'
                            ? calculateHref(item)
                            : detailCarHref(item)
                        }
                        onClick={(e) => {
                          if (page === 'main' || page === 'succesPage') {
                            if (isOTO) {
                              onClickInterested(e, item)
                            } else if (live) {
                              trackCountlyCtaClick(
                                item.brand,
                                item.model,
                                'https://' +
                                  window.location.hostname +
                                  calculateHref(item),
                              )
                              const valueForLcPageViewTracker =
                                pageOrigination === 'Homepage'
                                  ? PreviousButton.HomepageCarRecommendation
                                  : PreviousButton.CarRecommendationCta
                              saveDataForCountlyTrackerPageViewLC(
                                valueForLcPageViewTracker,
                              )
                            } else {
                              scrollToLeadsForm(e, item.brand, item.model)
                            }
                          } else {
                            onClickOnce(() => handleClickDetailCar(e, item))
                          }
                        }}
                        onMouseDown={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          page === 'main' || page === 'succesPage'
                            ? onClickOnce(() =>
                                handleCalculateAbilityMiddleRight(
                                  e,
                                  item,
                                  index,
                                ),
                              )
                            : onClickOnce(() =>
                                handleClickDetailCarMiddleRight(e, item),
                              )
                        }}
                      >
                        <Button
                          version={
                            page === 'main' || page === 'succesPage'
                              ? ButtonVersion.PrimaryDarkBlue
                              : ButtonVersion.Secondary
                          }
                          size={ButtonSize.Big}
                          data-testid={elementId.PLP.Button.HitungKemampuan}
                        >
                          {page === 'main' || page === 'succesPage'
                            ? isOTO
                              ? 'Saya Tertarik'
                              : live
                              ? 'Hitung Kemampuan'
                              : 'Minta Penawaran'
                            : 'Lihat Detail'}
                        </Button>
                      </Link>
                    </AlternativeCarCard>
                  </SwiperSlide>
                )
              })}
              {recommendationList.length > 0 && (
                <SwiperSlide
                  className={stylep.lihatSemuaWrapper}
                  role="button"
                  onClick={lihatSemuaMobil}
                  key={selectedBrand}
                >
                  <div className={stylep.wrapper}>
                    <IconChevronRight
                      color={colors.primaryDarkBlue}
                      height={24}
                      width={24}
                    />
                    <span>Lihat semua mobil {selectedBrand}</span>
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
          )}
        </div>
        {isModalOpenend && (
          <AdaOTOdiSEVALeadsForm onCancel={closeLeadsForm} onPage="LP" />
        )}
      </div>
      <PopupPromo
        data={promoModel}
        open={openPromo}
        onCancel={() => setOpenPromo(false)}
        isShowCta={true}
        onClickCta={onClickCtaPromoBadgePopup}
        isLoadingCta={isLoadingNavigteToLoanCalculator}
      />
      {openLeadsModal && (
        <LeadsFormPrimary
          onCancel={() => {
            setOpenLeadsModal(false)
          }}
          onPage={'LP'}
          trackerProperties={trackLeads()}
          eventGTM={'cotm'}
        />
      )}
    </>
  )
}

type EmptyCarRecommendationProps = {
  onClickChangeCity: () => void
}

const EmptyCarRecommendation = ({
  onClickChangeCity,
}: EmptyCarRecommendationProps) => (
  <div className={stylep.emptyCarRecommendation}>
    <Image
      src={EmptyCarImage}
      width={250}
      height={186}
      alt="empty car recommendation"
    />
    <h2>Mobil Tidak Ditemukan di Kotamu</h2>
    <span>
      Silakan{' '}
      <span className={stylep.link} onClick={onClickChangeCity}>
        ubah lokasimu.
      </span>
    </span>
  </div>
)

export default LpCarRecommendations

import { SwiperProps, SwiperSlideProps } from 'swiper/react'
import styles from 'styles/components/atoms/swiper.module.scss'
import { IconChevronLeft, IconChevronRight } from 'components/atoms/icon'
import { register } from 'swiper/element/bundle'
import { useRef, useEffect, useId, useMemo } from 'react'
import { colors } from 'utils/helpers/style/colors'

interface SwiperAtomProps extends SwiperProps {
  showArrow?: boolean
  chevronTop?: string
}

export const Swiper = ({
  showArrow = true,
  chevronTop,
  children,
  style,
  className,
  ...props
}: SwiperAtomProps) => {
  const ids = useId().replaceAll(':', '')

  const swiperRef = useRef<any>(null)

  const heightClient = useMemo(() => {
    if (swiperRef.current) return swiperRef.current.clientHeight

    return 0
  }, [swiperRef.current])

  const injectPaginationStyle = {
    injectStyles: [
      `
      .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: ${colors.shadesGrey25};
        opacity: 1;
        display: inline-block;
        margin: 0 4px;
      }

      .swiper-pagination-bullet-active {
        opacity: 1;
        background: ${colors.primarySkyBlue};
        width: 16px;
        height: 6px;
        border-radius: 20px;
      }
    `,
    ],
  }

  useEffect(() => {
    register()

    // Register Swiper web component

    const params = {
      ...props,
      navigation: {
        prevEl: '.arrow-button-prev-' + ids,
        nextEl: '.arrow-button-next-' + ids,
        enabled: true,
      },
      cssMode: true,
      ...(props.pagination && {
        pagination: {
          clickable: true,
          renderBullet: function (index: number, className: string) {
            return '<span class="' + className + '"></span>'
          },
        },
        ...injectPaginationStyle,
      }),
    }

    // Assign it to swiper element
    Object.assign(swiperRef.current, params)

    // initialize swiper
    swiperRef.current.initialize()
  }, [])

  return (
    <swiper-container
      init="false"
      style={style}
      class={className}
      ref={swiperRef}
    >
      {children}
      {showArrow ? (
        <>
          <div
            slot="container-start"
            className={`arrow-button-prev-${ids} ${styles.arrowButtonPrev}`}
            style={{ top: chevronTop || heightClient / 2 - 15 }}
          >
            <IconChevronLeft width={16} height={16} color="#13131B" />
          </div>
          <div
            slot="container-end"
            className={`arrow-button-next-${ids} ${styles.arrowButtonNext}`}
            style={{ top: chevronTop || heightClient / 2 - 15 }}
          >
            <IconChevronRight width={16} height={16} color="#13131B" />
          </div>
        </>
      ) : (
        <></>
      )}
    </swiper-container>
  )
}

export const SwiperSlide = ({
  children,
  className,
  ...props
}: SwiperSlideProps) => {
  return (
    <swiper-slide {...props} class={className}>
      {children}
    </swiper-slide>
  )
}

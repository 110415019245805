import Image, { ImageProps } from 'next/image'
import { useState } from 'react'

interface WithDefaultImageProps extends ImageProps {
  defaultSrc: string
}

export const WithDefaultImage = ({
  src,
  defaultSrc,
  ...props
}: WithDefaultImageProps) => {
  const [source, setSource] = useState(src || defaultSrc)

  return <Image {...props} src={source} onError={() => setSource(defaultSrc)} />
}
